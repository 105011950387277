<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isDialogOpen"
    max-width="420px"
    @click:outside="handleDiscard"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        {{ t('Add New Video') }}
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form
          ref="videoForm"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="videoId"
                autofocus
                outlined
                dense
                :rules="[validators.required]"
                :label="t('Video ID')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="loading"
              >
                {{ t('Submit') }}
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                :disabled="loading"
                @click.prevent="handleDiscard"
              >
                {{ t('Discard') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { getCurrentInstance, ref, watch } from '@vue/composition-api'
import { useUtils } from '@/@core/libs/i18n'
import { required } from '@core/utils/validation'

export default {
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
      default: () => {},
    },

    ignore: {
      type: Array,
      default: () => [],
    },
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()

    const videoForm = ref(null)
    const videoId = ref(null)

    const loading = ref(false)

    // videoDataLocal.value = JSON.parse(JSON.stringify(props.data))

    const handleDiscard = () => {
      loading.value = false
      videoId.value = null
      videoForm.value.reset()
      videoForm.value.resetErrorBag()
      videoForm.value.resetValidation()
      emit('update:is-dialog-open', false)
    }

    const fetchVideoMetaData = () =>
      vm.$http
        .post('/v1/academies/fetch-video-metadata', {
          videoId: videoId.value,
        })
        .then(res => res.status === 200 && res?.data?.data && props.submit(res.data.data))

        // .catch(err => console.error(err))
        .finally(() => handleDiscard())

    // on form submit
    const onSubmit = () => {
      const isFormValid = videoForm.value.validate()
      if (!isFormValid) return

      if (props.ignore.includes(videoId.value)) {
        handleDiscard()

        return
      }

      loading.value = true
      fetchVideoMetaData()
    }

    return {
      t,

      validators: {
        required,
      },

      loading,
      videoForm,
      onSubmit,

      videoId,

      handleDiscard,
    }
  },
}
</script>
