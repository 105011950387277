import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"420px"},on:{"click:outside":_vm.handleDiscard},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c(VCard,{staticClass:"user-edit-info pa-sm-10 pa-3"},[_c(VCardTitle,{staticClass:"justify-center text-h5"},[_vm._v(" "+_vm._s(_vm.t('Add New Video'))+" ")]),_c(VCardText,{staticClass:"mt-5"},[_c(VForm,{ref:"videoForm",staticClass:"multi-col-validation",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c(VTextField,{attrs:{"autofocus":"","outlined":"","dense":"","rules":[_vm.validators.required],"label":_vm.t('Video ID')},model:{value:(_vm.videoId),callback:function ($$v) {_vm.videoId=$$v},expression:"videoId"}})],1),_c(VCol,{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.t('Submit'))+" ")]),_c(VBtn,{attrs:{"outlined":"","color":"secondary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.handleDiscard.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.t('Discard'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }