<template>
  <v-card
    v-if="!loading"
    class="user-edit-info pa-sm-10 pa-3"
  >
    <v-card-title class="justify-start text-h5">
      <span v-if="action === 'create'">
        {{ t('Create New Academy') }}
      </span>
      <span v-if="action === 'update'">
        {{ t('Edit Academy Information') }}
      </span>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-form
        ref="academyForm"
        class="multi-col-validation"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="data.title"
              name="title"
              :label="t('Title')"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <!-- <v-text-field
              v-model="data.thumbUrl"
              name="thumbUrl"
              :label="t('Thumbnail URL')"
              :rules="[validators.urlValidator]"
              :error-messages="errorMessages.thumbUrl"
            ></v-text-field> -->

            <!-- <v-file-input
              v-model="data.thumbUrl"
              truncate-length="100"
              name="image"
              :label="t('Image')"
              :error-messages="errorMessages.thumbUrl"
            ></v-file-input> -->

            <v-file-pond
              :controller.sync="pondController"
              name="image"
              disk="academies"
              max-files="1"
              class="w-100"
              :label="t('Image')"
              max-paralel-uploads="1"
              :allow-multiple="false"
              :errors="errorMessages.image"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-row align="end">
              <v-col
                align="start"
              >
                <label class="v-label">
                  <div class="mb-4">Videos</div>
                </label>
              </v-col>
              <v-col align="end">
                <v-btn
                  x-small
                  color="primary"
                  class="mb-4 me-3"
                  @click="isDialogOpen = !isDialogOpen"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                  <span>Add New Video</span>
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table
              v-sortable-data-table
              dense
              :headers="videoTableColumns"
              :items="academyVideos"
              :item-class="renderRowClass"
              item-key="sort"
              disable-pagination
              hide-default-footer
              @sorted="handleOrder"
            >
              <template #[`item.duration`]="{item}">
                <div
                  v-if="item.duration"
                  class="d-flex flex-column"
                >
                  <span>{{ item.duration }}s</span>
                  <small>({{ item.duration | parseMinutes }})</small>
                </div>
              </template>

              <template
                #[`item.thumbUrl`]="{item}"
              >
                <div class="text--ellipsis">
                  <a
                    v-if="item.thumbUrl"
                    :href="item.thumbUrl"
                    target="_blank"
                  >
                    {{ item.thumbUrl | textEllipsis(75) }}
                  </a>
                </div>
              </template>

              <template #[`item.actions`]="{item}">
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      :href="vimeoUrlGenerator(item.videoId)"
                      :disabled="!item.videoId"
                      target="blank"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('View Video') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      color="error"
                      v-bind="attrs"
                      :disabled="!item.videoId || deleting"
                      :loading="isDialogOpen"
                      @click="removeVideo(item.videoId)"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Delete Video') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-if="isDirty">
            <v-col
              cols="12"
              class="d-flex justify-start mt-3"
            >
              <v-banner min-width="100%">
                <v-icon
                  slot="icon"
                  color="warning"
                  size="36"
                >
                  {{ icons.mdiUpdate }}
                </v-icon>

                <div class="d-flex flex-column">
                  <span>{{ t("Academy videos have changed!") }}</span>
                  <small>{{ t("Don't forget to submit the form.") }}</small>
                </div>
              </v-banner>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-start mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
            >
              {{ t('Submit') }}
            </v-btn>

            <v-btn
              v-if="handleDiscard"
              outlined
              color="secondary"
              class="me-3"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
              @click="handleDiscard"
            >
              {{ t('Discard') }}
            </v-btn>
          </v-col>
        </v-row>

        <video-dialog
          :is-dialog-open.sync="isDialogOpen"
          :ignore="dialogIgnoreIds"
          :submit="videoAddedCallback"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import {
  computed,
  onMounted,
  ref,
} from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiPlus, mdiUpdate, mdiEyeOutline, mdiDeleteOutline } from '@mdi/js'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'

import VideoDialog from './VideoDialog.vue'

export default {
  name: 'AcademyForm',

  components: {
    VideoDialog,
  },

  props: {
    action: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
      default: () => ({
        videos: [],
      }),
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },

    handleSubmit: {
      type: Function,
      required: true,
      default: () => {},
    },
    handleDiscard: {
      type: Function,
      default: null,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()

    const academyForm = ref(null)
    const isDialogOpen = ref(false)

    const academyVideos = ref(Array.from(props.data.videos))

    const propIds = computed(() => Array.from(props.data.videos.map(v => v.videoId)))
    const localIds = computed(() => Array.from(academyVideos.value.map(v => v.videoId)))

    const isDirty = computed(() => {
      if (propIds.value.length !== localIds.value.length) return true

      if (propIds.value.some(id => !localIds.value.includes(id))) return true

      if (localIds.value.some(id => !propIds.value.includes(id))) return true

      if (localIds.value.toString() !== propIds.value.toString()) return true

      return false
    })

    const pondController = ref({
      files: [],
      uploading: false,
    })

    onMounted(() => {
      if (props.data.image && props.data.thumbUrl) {
        pondController.value.files = [{
          source: props.data.image,
          options: {
            type: 'local',
          },
        }]
      }
    })

    const onSubmit = () => {
      const isFormValid = academyForm.value.validate()
      if (!isFormValid) return
      const formData = new FormData(academyForm.value.$el)

      academyVideos.value.forEach((video, i) => {
        if (!video.videoId) return

        formData.append(`videos[${i}][videoId]`, video.videoId)
        formData.append(`videos[${i}][title]`, video.title)
        formData.append(`videos[${i}][duration]`, video.duration)
        formData.append(`videos[${i}][thumbUrl]`, video.thumbUrl)
      })

      props.handleSubmit(formData)
    }

    const videoTableColumns = [
      { text: '#', value: 'videoId', width: '10%' },
      { text: 'TITLE', value: 'title', width: '45%' },
      { text: 'DURATION', value: 'duration', width: '10%' },
      { text: 'THUMBNAIL URL', value: 'thumbUrl', width: '15%' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
        width: '20%',
      },
    ]
    const renderRowClass = item => `video-${item.sort}`

    const videoAddedCallback = video => {
      if (!academyVideos.value.find(v => String(v.videoId) === String(video.videoId))) {
        academyVideos.value.push(video)
      }
    }

    const vimeoUrlGenerator = videoId => `https://player.vimeo.com/video/${videoId}`

    const removeVideo = videoId => {
      if (!videoId) return

      const videoIndex = academyVideos.value.findIndex(v => String(v.videoId) === String(videoId))
      if (videoIndex !== -1) academyVideos.value.splice(videoIndex, 1)
    }

    const handleOrder = e => {
      // const replacedItem = Array.from(academyVideos.value).splice(e.newIndex, 1)[0]
      const movedItem = academyVideos.value[e.oldIndex]

      academyVideos.value.splice(e.oldIndex, 1)
      academyVideos.value.splice(e.newIndex, 0, movedItem)
    }

    return {
      t,

      icons: {
        mdiPlus,
        mdiUpdate,
        mdiEyeOutline,
        mdiDeleteOutline,
      },

      validators: {
        required,
        urlValidator,
        emailValidator,
        integerValidator,
      },

      renderRowClass,
      videoTableColumns,

      academyVideos,

      isDialogOpen,

      academyForm,
      onSubmit,

      videoAddedCallback,
      vimeoUrlGenerator,
      removeVideo,
      handleOrder,

      isDirty,
      dialogIgnoreIds: localIds,

      pondController,
    }
  },
}
</script>
